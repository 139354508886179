import { Component, OnInit } from '@angular/core';
import { Router, Routes, RouterModule } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-manager-all',
	templateUrl: './manager-all.component.html',
	styleUrls: ['./manager-all.component.css']
})

export class ManagerAllComponent implements OnInit {

	public managers: Array<any>;
	public filteredItems: Array<any>;
	term = "";

	limit: number = 10;
	skip: number = 0;
	public pages: any = [];
	currentPage = 1;
	stringTrue = true;

	constructor(private apiService: ApiService, private toastr: ToastrService) { }

	ngOnInit(value: string = '') {
		this.apiService.getAllManagerss('', 1, 0).subscribe(data => {
			if (data.data.totalCount == 0) this.stringTrue = false;

			this.apiService.getAllManagerss(value, this.limit, this.skip).subscribe(data => {
				this.managers = data.data.ManagersArray;
				this.filteredItems = data.data.ManagersArray;
				let length = Math.ceil(data.data.totalCount / this.limit);
				this.pages = [];
				for (let i = 1; i <= length; i++) {
					this.pages.push(i);
				}
			}, error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}, error => {
			if (error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});

		this.assignCopy();
	}

	assignCopy() {
		this.filteredItems = Object.assign([], this.managers);
	}

	filterItem(value) {
		if (!value) this.assignCopy();

		this.filteredItems = Object.assign([], this.managers).filter(
			item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
		)
	}

	setPage(page) {
		this.currentPage = page;
		this.skip = (this.currentPage - 1) * this.limit;
		this.apiService.getAllManagerss('', this.limit, this.skip).subscribe(data => {
			this.managers = data.data.ManagersArray;
			this.filteredItems = data.data.ManagersArray;
		},
			error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});

		this.assignCopy();
	}
}