import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../guards/auth/auth.guard';

import { ManagerAllComponent } from '../components/managers/manager-all/manager-all.component';
import { ManagerAddComponent } from '../components/managers/manager-add/manager-add.component';
import { ManagerProfileUpdateComponent } from '../components/managers/manager-profile-update/manager-profile-update.component';

const managersRoutes: Routes = [
	{ path: 'managers',  component: ManagerAllComponent, canActivate: [AuthGuard], data: { title: 'Managers' }},
	{ path: 'manager/add',  component: ManagerAddComponent, canActivate: [AuthGuard], data: { title: 'Add Manager' }},
	{ path: 'manager/:id', component: ManagerProfileUpdateComponent, canActivate: [AuthGuard], data: { title: 'Manager Details' }}
];

@NgModule({
	imports: [
		RouterModule.forChild(managersRoutes)
	],
	exports: [
		RouterModule
	]
})
export class ManagerRoutingModule { }