import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTEES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
	selector: 'navbar-cmp',
	templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

	private listTitles: any[];
	location: Location;
	private toggleButton: any;
	private sidebarVisible: boolean;

	constructor(
		location: Location,
		private router: Router,
		private element: ElementRef
		) {
		this.location = location;
		this.sidebarVisible = false;
	}

	ngOnInit(){
		this.listTitles = ROUTEES.filter(listTitle => listTitle);
		const navbar: HTMLElement = this.element.nativeElement;
		this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
	}

	sidebarOpen() {
		const toggleButton = this.toggleButton;
		const body = document.getElementsByTagName('body')[0];
		setTimeout(function(){
			toggleButton.classList.add('toggled');
		}, 500);
		body.classList.add('nav-open');

		this.sidebarVisible = true;
	};

	sidebarClose() {
		const body = document.getElementsByTagName('body')[0];
		this.toggleButton.classList.remove('toggled');
		this.sidebarVisible = false;
		body.classList.remove('nav-open');
	};
	
	sidebarToggle() {
		if (this.sidebarVisible === false) {
			this.sidebarOpen();
		} else {
			this.sidebarClose();
		}
	};

	getTitle(){
		// var titlee = this.location.prepareExternalUrl(this.location.path());
		var titlee = this.location.path().split('/', 3);
		for(var item = 0; item < this.listTitles.length; item++) {
			if(this.listTitles[item].path === this.location.path() && this.listTitles[item].title) {
				return this.listTitles[item].title;
			}
		}
		return 'Dashboard';
	}

	clear() {
		sessionStorage.clear();
	}

}
