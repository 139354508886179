import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { ToastrService } from 'ngx-toastr';

declare interface RouteInfo {
	path: string;
	title: string;
	icon: string;
}

declare interface RouteeInfo {
	path: string;
	title: string;
}

export const ROUTES: RouteInfo[] = [
	// { path: 'dashboard', title: 'Dashboard', icon: 'fa fa-home' },
	{ path: 'sites', title: 'Sites', icon: 'fa fa-home' },
	{ path: 'managers', title: 'Managers', icon: 'fa fa-users' },
	{ path: 'billing', title: 'Billing', icon: 'fa fa-money' }
];

export const ROUTEES: RouteeInfo[] = [
	{ path: '/sites', title: 'Sites' },
	{ path: '/site/:id', title: 'Site Details' },
	{ path: '/managers', title: 'Managers' },
	{ path: '/user/profile-update', title: 'Profile Update' },
	{ path: '/user/cpassword', title: 'Change Password' },
	{ path: '/manager/add', title: 'Add Manager' },
	{ path: '/manager/:id', title: 'Manager Details' },
	{ path: '/billing', title: 'Billing' }
];

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {

	menuItems: any[];
	currentPath: string;
	sitesCount: number;
	archivedSitesCount: number;
	deletedSitesCount: number;
	managersCount: number;

	constructor(
		private router: Router,
		private apiService: ApiService,
		private toastr: ToastrService
	) {
		router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.currentPath = event.url
			}
		});
	}

	ngOnInit() {
		this.menuItems = ROUTES.filter(menuItem => menuItem);

		if (localStorage.getItem('access_token')) {
			this.apiService.getAllSitess('', 1, 0, 'title', 'ascending', false, false).subscribe(data => {
				this.sitesCount = data.data.totalCount;
			}, error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});

			this.apiService.getAllSitess('', 1, 0, 'title', 'ascending', true, false).subscribe(data => {
				this.archivedSitesCount = data.data.totalCount;
			}, error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});

			this.apiService.getAllSitess('', 1, 0, 'title', 'ascending', true, true).subscribe(data => {
				this.deletedSitesCount = data.data.totalCount;
			}, error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});

			this.apiService.getAllManagerss('', 1, 0).subscribe(data => {
				this.managersCount = data.data.totalCount;
			}, error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}
	}
}
