import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../guards/auth/auth.guard';

import { HomeComponent } from '../components/home/home.component';
import { SiteDetailsComponent } from '../components/home/site-details/site-details.component';

const sitesRoutes: Routes = [
	{ path: 'sites', component: HomeComponent, canActivate: [AuthGuard], data: { title: 'Sites' } },
	{ path: 'archivedSites', component: HomeComponent, canActivate: [AuthGuard], data: { title: 'Archived Sites' } },
	{ path: 'deletedSites', component: HomeComponent, canActivate: [AuthGuard], data: { title: 'Deleted Sites' } },
	{ path: 'site/:id', component: SiteDetailsComponent, canActivate: [AuthGuard], data: { title: 'Site Details' } }
];

@NgModule({
	imports: [
		RouterModule.forChild(sitesRoutes)
	],
	exports: [
		RouterModule
	]
})
export class SiteRoutingModule { }