import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent {
	panel: boolean = false;

	constructor() {}

	ngOnInit() {
		if(localStorage.getItem('access_token')) {
			this.panel = true;
		}
	}
}
