import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services/api.service';
// import { Router } from '@angular/router';

@Component({
	selector: 'app-cpassword',
	templateUrl: './cpassword.component.html',
	styleUrls: ['./cpassword.component.css']
})

export class CpasswordComponent implements OnInit {
	
	cPassword: FormGroup;
	public profile: any;
	submitted: boolean = false;
	eye: string = 'show';
	eyeSlash: string = 'hide';
	
	constructor(
		private apiService: ApiService,
		// private router: Router,
		private fb: FormBuilder, 
		private toastr: ToastrService
		) {
		this.cPassword = this.fb.group({
			// password: new FormControl('', [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'), Validators.required, Validators.minLength(6)]),
			password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]),
			// passwordConfirm: new FormControl('', [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'), Validators.required, Validators.minLength(6)]),
			passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)])
		}, {
			validator: this.passwordMatchValidator
		});
	}

	ngOnInit() {
		this.apiService.getCompanyProfile().subscribe(data => {
			this.profile = data.data.CompanyArray[0];
		},
		error => {
			if(error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");				
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});
	}

	passwordMatchValidator(fg: FormGroup) {
		return fg.get('password').value === fg.get('passwordConfirm').value ? null : fg.get('passwordConfirm').setErrors( { mismatch: true } );
	}

	showPassword(pass: any): any {
		pass.type = 'text';
		this.eye = 'hide';
		this.eyeSlash = 'show';
	}

	hidePassword(pass: any): any {
		pass.type = 'password';
		this.eye = 'show';
		this.eyeSlash = 'hide';
	}

	changePassword() {
		if (this.cPassword.valid) {
			this.submitted = true;
			this.apiService.changePassword(this.cPassword.value.password).subscribe(data => {
				this.submitted = false;
				this.cPassword.reset();
				this.toastr.success('', data.message);
				// window.open("/sites", "_self");
				// this.router.navigate(['/sites']);
			},
			error => {
				this.submitted = false;
				if(error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");				
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}
	}
}