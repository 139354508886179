import { Injectable } from '@angular/core';
import { Http, Response, Headers, Request, RequestMethod, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiService {
	// LOCAL
	// private baseUrl: string = 'http://asusx550c:8013/api/admin';
	// private baseUrlManager: string = 'http://asusx550c:8013/api/Manager';

	// DEV
	// private baseUrl: string = 'http://app.guildist.com:8001/api/admin';
	// private baseUrlManager: string = 'https://app.guildist.com:8001/api/Manager';

	// ALPHA-ONE
	// private baseUrl: string = 'https://alpha.novagems.io/api/admin';
	// private baseUrlManager: string = 'https://alpha.novagems.io/api/Manager';

	// BETA-ONE
	// private baseUrl: string = 'https://app.novagems.io/api/admin';
	// private baseUrlManager: string = 'https://app.novagems.io/api/Manager';

	// ALPHA-TWO
	// private baseUrl: string = 'https://alphatwo.novagems.io/api/admin';
	// private baseUrlManager: string = 'https://alphatwo.novagems.io/api/Manager';

	// BETA-TWO
	// private baseUrl: string = 'https://betatwo.novagems.io/api/admin';
	// private baseUrlManager: string = 'https://betatwo.novagems.io/api/Manager';

	// HOTFIX
	// private baseUrl: string = 'https://hotfix.novage.ms/api/admin';
	// private baseUrlManager: string = 'https://hotfix.novage.ms/api/Manager';

	// LIVE
	private baseUrl: string = 'https://templive.novagems.io/api/admin';//'https://app.novage.ms/api/admin';
	private baseUrlManager: string = 'https://templive.novagems.io/api/Manager';//'https://app.novage.ms/api/Manager';

	constructor(private http: Http) { }

	private getHeaders() {
		let headers = new Headers();
		headers.append('Accept', 'application/json');
		return headers;
	}

	private getHeadersWithToken() {
		let headers = new Headers();
		let firstTimeLogin_token = localStorage.getItem('firstTimeLogin_token');
		let change_password_token = localStorage.getItem('change_password_token');
		let access_token = localStorage.getItem('access_token');
		if (firstTimeLogin_token && firstTimeLogin_token != '' && firstTimeLogin_token != null) {
			headers.append('Accept', 'application/json');
			headers.append('Authorization', `Bearer ${firstTimeLogin_token}`);
		} else if (change_password_token && change_password_token != '' && change_password_token != null) {
			headers.append('Accept', 'application/json');
			headers.append('Authorization', `Bearer ${change_password_token}`);
		} else {
			headers.append('Accept', 'application/json');
			headers.append('Authorization', `Bearer ${access_token}`);
		}
		return headers;
	}

	login(username: string, password: string) {
		return this.http
			.post(`${this.baseUrl}/login`, { userName: username, password: password }, { headers: this.getHeaders() })
			.pipe(map(res => res.json()));
	}

	logout(access_token: string) {
		return this.http
			.put(`${this.baseUrl}/logout`, { access_token: access_token }, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	forgetPassword(userName: string) {
		return this.http
			.post(`${this.baseUrl}/forgotPassword`, { userName: userName }, { headers: this.getHeaders() })
			.pipe(map(res => res.json()));
	}

	resendOTP(userName: string) {
		return this.http
			.put(`${this.baseUrl}/resendOTP`, { userName: userName }, { headers: this.getHeaders() })
			.pipe(map(res => res.json()));
	}

	changePassword(newPassword: string) {
		// console.log('this.getHeadersWithToken()---', this.getHeadersWithToken());
		return this.http
			.put(`${this.baseUrl}/changePassword`, { newPassword }, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	checkOtp(userName: string, generatedOTP: string) {
		return this.http
			.post(`${this.baseUrl}/checkOTP`, { userName: userName, generatedOTP: generatedOTP }, { headers: this.getHeaders() })
			.pipe(map(res => res.json()));
	}

	getAllGuards() {
		return this.http
			.get(`${this.baseUrl}/getAllGuards`, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	getGuard(guardId: string) {
		let options = new RequestOptions({ headers: this.getHeadersWithToken(), params: { 'guardId': guardId } });

		return this.http
			.get(`${this.baseUrl}/getGuard`, options)
			.pipe(map(res => res.json()));
	}

	getCompanyProfile() {
		return this.http
			.get(`${this.baseUrl}/getCompanyProfile`, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	updateCompany(formData: FormData) {
		return this.http
			.put(`${this.baseUrl}/updateCompany`, formData, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	getAllSites() {
		return this.http
			.get(`${this.baseUrl}/getAllSites`, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	getAllSitess(keyword: string, limit: number, skip: number, sortBy: string, sortOrder: string, isArchived: boolean, isDeleted: boolean) {
		let url = `${this.baseUrl}/getAllSites?limit=${limit}&skip=${skip}&sortBy=${sortBy}&sortOrder=${sortOrder}&isArchived=${isArchived}&isDeleted=${isDeleted}`;
		if (keyword) url += `&keyword=${keyword}`;
		return this.http
			.get(url, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	getSite(siteId: string) {
		let options = new RequestOptions({ headers: this.getHeadersWithToken(), params: { 'siteId': siteId } });

		return this.http
			.get(`${this.baseUrl}/getSite`, options)
			.pipe(map(res => res.json()));
	}

	addManager(formData: FormData) {
		return this.http
			.post(`${this.baseUrl}/addManager`, formData, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	getAllManagers() {
		return this.http
			.get(`${this.baseUrl}/getAllManagers`, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	getAllManagersCount(limit: number = 0, skip: number = 0) {
		if (limit == 0)
			return this.http
				.get(`${this.baseUrl}/getAllManagersCount`, { headers: this.getHeadersWithToken() })
				.pipe(map(res => res.json()));
		else
			return this.http
				.get(`${this.baseUrl}/getAllManagers?limit=` + limit + `&skip=` + skip, { headers: this.getHeadersWithToken() })
				.pipe(map(res => res.json()));
	}

	getAllManagerss(keyword: string, limit: number, skip: number) {
		let url = `${this.baseUrl}/getAllManagers?limit=${limit}&skip=${skip}`;
		if (keyword) url += `&keyword=${keyword}`;
		return this.http
			.get(url, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	getManager(managerId: string) {
		let options = new RequestOptions({ headers: this.getHeadersWithToken(), params: { 'managerId': managerId } });

		return this.http
			.get(`${this.baseUrl}/getManager`, options)
			.pipe(map(res => res.json()));
	}

	updateManager(formData: FormData) {
		return this.http
			.put(`${this.baseUrl}/updateManager`, formData, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	assignManager(formData: FormData) {
		return this.http
			.put(`${this.baseUrl}/assignManager`, formData, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	unAssignManager(formData: FormData) {
		return this.http
			.put(`${this.baseUrl}/unAssignManager`, formData, { headers: this.getHeadersWithToken() })
			.pipe(map(res => res.json()));
	}

	resendOTPForManager(userName: string) {
		return this.http
			.put(`${this.baseUrlManager}/resendOTP`, { userName: userName }, { headers: this.getHeaders() })
			.pipe(map(res => res.json()));
	}

}
