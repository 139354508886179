import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ng2-ui-switch';
// import { LightboxModule } from 'angular2-lightbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppComponent } from './app.component';
import { NavbarModule } from './modules/shared/navbar/navbar.module';
import { SidebarModule } from './modules/shared/sidebar/sidebar.module';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './guards/auth/auth.guard';

import { LoginComponent } from './components/user/login/login.component';
import { LogoutComponent } from './components/user/logout/logout.component';
import { ChangePasswordComponent } from './components/user/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';
import { ProfileUpdateComponent } from './components/user/profile-update/profile-update.component';
import { CpasswordComponent } from './components/user/cpassword/cpassword.component';
import { BillingComponent } from './components/billing/billing.component';

import { AppRoutingModule } from './app-routing.module';
import { SitesModule } from './modules/sites.module';
import { ManagersModule } from './modules/managers.module';
// import { DashboardModule } from './modules/dashboard.module';


@NgModule({
	declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        ChangePasswordComponent,
        ForgotPasswordComponent,
        ProfileUpdateComponent,
        CpasswordComponent,
        BillingComponent
    ],
    imports: [
        BrowserModule,
        HttpModule,
        FormsModule,
        NavbarModule,
        SidebarModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        ToastrModule.forRoot(),
        UiSwitchModule,
        // LightboxModule,
        ReactiveFormsModule,
        NgSelectModule,
        SitesModule,
        // DashboardModule,
        ManagersModule,
        AppRoutingModule
    ],
    providers: [ApiService, AuthGuard, AuthService],
    bootstrap: [AppComponent]
})
export class AppModule { }
