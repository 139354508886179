import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
	selector: 'app-manager-add',
	templateUrl: './manager-add.component.html',
	styleUrls: ['./manager-add.component.css']
})

export class ManagerAddComponent implements OnInit {

	addManager: FormGroup;
	file: File;
	submitted: boolean = false;
	size: number;

	constructor(private apiService: ApiService, private router: Router, private _location: Location, private fb: FormBuilder, private toastr: ToastrService) { 
		this.addManager = this.fb.group({
			userName: new FormControl('', Validators.required),
			firstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
			lastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
			email: new FormControl('', Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)),
			// email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
			phoneNo: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(6), Validators.maxLength(25)]),
			countryCode: new FormControl('', [Validators.required, Validators.pattern(/^\+[1-9][0-9]*$/)]),
			gender: new FormControl('', Validators.required),
			address: new FormControl(''),
			role: new FormControl('Manager', Validators.required),
			language: new FormControl('en', Validators.required),
		});
	}

	ngOnInit() { }

	back() {
		this.router.navigate(['/managers']);
	}

	fileSelected(imageData) {
		this.size = imageData.size;
		if (imageData.size > 3072000) {
			this.toastr.error('Payload content size should be less than 3MB', 'Error');
		} else {
			this.file = imageData;
		}
	}

	AdminAddManager() {
		if (this.addManager.valid) {
			this.submitted = true;

			let formData: FormData = new FormData();
			formData.append('userName', this.addManager.value.userName.trim());
			formData.append('firstName', this.addManager.value.firstName.trim());
			formData.append('lastName', this.addManager.value.lastName.trim());
			formData.append('email', this.addManager.value.email.trim());
			formData.append('phoneNo', this.addManager.value.phoneNo.trim());
			formData.append('countryCode', this.addManager.value.countryCode.trim());
			formData.append('gender', this.addManager.value.gender);
			formData.append('address', this.addManager.value.address.trim());
			formData.append('role', this.addManager.value.role == 'Manager' ? 'manager' : 'super_manager');
			formData.append('language', this.addManager.value.language);

			if (this.file) {
				formData.append('profilePic', this.file);
			}			
			this.apiService.addManager(formData).subscribe(data => {
				this.submitted = false;
				this.toastr.success('', data.message);
				this.back();
			},
			error => {
				this.submitted = false;
				if(error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");				
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}
	}
}
