import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

	forgotPassword: FormGroup;
	checkotp = false;
	submitted: boolean = false;

	constructor(private apiService: ApiService, private router: Router, private fb: FormBuilder, private toastr: ToastrService) { 
		this.forgotPassword = this.fb.group({
			userName: new FormControl('', Validators.required),
			otp: new FormControl('', Validators.required)
		});
	}

	ngOnInit() { }

	forgetPassword() {
		if (this.forgotPassword.controls.userName.status) {
			this.submitted = true;
			this.apiService.forgetPassword(this.forgotPassword.value.userName).subscribe(data => {
				this.checkotp = true;
				this.toastr.success('', 'Please check your email for one time password.');
				this.submitted = false;
			},
			error => {
				this.checkotp = false;
				this.submitted = false;
				if(error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");				
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}
	}

	resendOtp() {
		if (this.forgotPassword.controls.userName.status) {
			this.apiService.resendOTP(this.forgotPassword.value.userName).subscribe(data => {
				this.toastr.success('', data.message);;
			},
			error => {
				this.toastr.error('Please, try again!', 'Error');
			});
		}
	}

	submitOtp() {
    	if (this.forgotPassword.valid) {
    		this.submitted = true;
			this.apiService.checkOtp(this.forgotPassword.value.userName, this.forgotPassword.value.otp).subscribe(data => {
				this.checkotp = true;
				this.toastr.success('', data.message);;
				localStorage.setItem('temp_userName', this.forgotPassword.value.userName);
				localStorage.setItem('temp_otp', this.forgotPassword.value.otp);
				localStorage.setItem('change_password_token', data.data.accessToken);
				this.router.navigate(['/changepassword']);
				this.submitted = false;
			},
			error => {
				// this.checkotp = false;
				this.submitted = false;
				if(error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");				
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}
	}
}
