import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { AuthService } from "../.././../services/auth/auth.service";

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {

	passwordChange: FormGroup;

	eye = 'show';
	eyeSlash = 'hide';
	submitted: boolean = false;
	
	constructor(private apiService: ApiService, private router: Router, private fb: FormBuilder, private toastr: ToastrService, private authService: AuthService) {
		this.passwordChange = this.fb.group({
			email: new FormControl({ value: localStorage.getItem('temp_email'), disabled: true }, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
			// password: new FormControl('', [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'), Validators.required, Validators.minLength(6)]),
			password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]),
			// passwordConfirm: new FormControl('', [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'), Validators.required, Validators.minLength(6)])
			passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)])
		}, {
			validator: this.passwordMatchValidator
		});
	}

	ngOnInit() {
		if (localStorage.getItem('temp_email') === '' && localStorage.getItem('temp_otp') === '') {
			this.router.navigate(['/forgotpassword']);
		}
	}

	passwordMatchValidator(fg: FormGroup) {
		return fg.get('password').value === fg.get('passwordConfirm').value ? null : fg.get('passwordConfirm').setErrors( { mismatch: true } );
	}

	showPassword(pass: any): any {
  		pass.type = 'text';
  		this.eye = 'hide';
  		this.eyeSlash = 'show';
  	}

  	hidePassword(pass: any): any {
  		pass.type = 'password';
  		this.eye = 'show';
  		this.eyeSlash = 'hide';
  	}

	changePassword() {
		if (this.passwordChange.valid) {
			this.submitted = true;
			this.apiService.changePassword(this.passwordChange.value.password).subscribe(data => {
				this.submitted = false;
				let firstTimeLogin_token = localStorage.getItem('firstTimeLogin_token');
				this.toastr.success('', data.message);
				if(firstTimeLogin_token && firstTimeLogin_token != null && firstTimeLogin_token != '') {
					localStorage.setItem('access_token', firstTimeLogin_token);
					localStorage.removeItem('firstTimeLogin_token');
					window.open("/sites", "_self");
					// this.router.navigate(['/sites']);
				} else {
					localStorage.clear();
					sessionStorage.clear();
					this.router.navigate(['/login']);
				}
			},
			error => {
				localStorage.clear();
				this.submitted = false;
				if(error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");				
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}
	}
}