import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HomeComponent } from '../components/home/home.component';
import { SiteDetailsComponent } from '../components/home/site-details/site-details.component';

import { ApiService } from '../services/api.service';
import { SiteRoutingModule } from './sites-routing.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SiteRoutingModule,
		MatProgressSpinnerModule
	],
	declarations: [
		HomeComponent,
		SiteDetailsComponent
	],
	providers: [ ApiService ]
})
export class SitesModule {}