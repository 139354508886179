import { Component, OnInit } from '@angular/core';
import { Router, Routes, RouterModule, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

	public sites: Array<any>;
	public filteredItems: Array<any>;
	term = "";

	limit: number = 10;
	skip: number = 0;
	public pages: any = [];
	currentPage = 1;

	constructor(private apiService: ApiService, private toastr: ToastrService, private activatedRoute: ActivatedRoute) { }

	stringTrue = true;

	ngOnInit(value: string = '') {
		this.activatedRoute.queryParams.subscribe(() => {
			if (this.activatedRoute.snapshot.data.title == "Archived Sites") {
				this.apiService.getAllSitess('', 1, 0, 'title', 'ascending', true, false).subscribe(data => {
					if (data.data.totalCount == 0) this.stringTrue = false;
					this.apiService.getAllSitess(value, this.limit, this.skip, 'title', 'ascending', true, false).subscribe(data => {
						this.sites = data.data.siteDetails;
						let length = Math.ceil(data.data.totalCount / this.limit);
						this.pages = []
						for (let i = 1; i <= length; i++) {
							this.pages.push(i)
						}
						this.filteredItems = data.data.siteDetails;
					}, error => {
						if (error.statusText == 'Unauthorized') {
							window.open("/logout", "_self");
						} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
					});
				}, error => {
					if (error.statusText == 'Unauthorized') {
						window.open("/logout", "_self");
					} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
				});
			} else if (this.activatedRoute.snapshot.data.title == "Deleted Sites") {
				this.apiService.getAllSitess('', 1, 0, 'title', 'ascending', true, true).subscribe(data => {
					if (data.data.totalCount == 0) this.stringTrue = false;

					this.apiService.getAllSitess(value, this.limit, this.skip, 'title', 'ascending', true, true).subscribe(data => {
						this.sites = data.data.siteDetails;
						let length = Math.ceil(data.data.totalCount / this.limit);
						this.pages = []
						for (let i = 1; i <= length; i++) {
							this.pages.push(i)
						}
						this.filteredItems = data.data.siteDetails;
					}, error => {
						if (error.statusText == 'Unauthorized') {
							window.open("/logout", "_self");
						} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
					});
				}, error => {
					if (error.statusText == 'Unauthorized') {
						window.open("/logout", "_self");
					} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
				});
			} else {
				this.apiService.getAllSitess('', 1, 0, 'title', 'ascending', false, false).subscribe(data => {
					if (data.data.totalCount == 0) this.stringTrue = false;
					this.apiService.getAllSitess(value, this.limit, this.skip, 'title', 'ascending', false, false).subscribe(data => {
						this.sites = data.data.siteDetails;
						let length = Math.ceil(data.data.totalCount / this.limit);
						this.pages = []
						for (let i = 1; i <= length; i++) {
							this.pages.push(i)
						}
						this.filteredItems = data.data.siteDetails;
					}, error => {
						if (error.statusText == 'Unauthorized') {
							window.open("/logout", "_self");
						} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
					});
				}, error => {
					if (error.statusText == 'Unauthorized') {
						window.open("/logout", "_self");
					} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
				});
			}
			this.assignCopy();
		});
	}

	assignCopy() {
		this.filteredItems = Object.assign([], this.sites);
	}

	filterItem(value) {
		if (!value) this.assignCopy();

		this.filteredItems = Object.assign([], this.sites).filter(
			item => item.title && item.title.toLowerCase().indexOf(value.toLowerCase()) > -1
		)
	}

	setPage(page) {
		this.currentPage = page;
		this.skip = (this.currentPage - 1) * this.limit;
		this.apiService.getAllSitess('', this.limit, this.skip, 'title', 'ascending', false, false).subscribe(data => {
			this.sites = data.data.siteDetails;
			this.pages.length = Math.ceil(data.data.totalCount / this.limit);
			this.filteredItems = data.data.siteDetails;
		},
			error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});

		this.assignCopy();
	}
}