import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router, Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
// import { } from 'googlemaps';

import { MapsAPILoader } from '@agm/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
	selector: 'app-manager-profile-update',
	templateUrl: './manager-profile-update.component.html',
	styleUrls: ['./manager-profile-update.component.css']
})

export class ManagerProfileUpdateComponent implements OnInit {

	@ViewChild("search")
	public searchElementRef: ElementRef;

	selected: string;
	scheduleValue: boolean;
	watchValue: boolean;
	submitted: boolean = false;
	public manager: any;
	public sites: Array<any>;
	public filteredAssignedSites: Array<any>;
	public filteredUnassignedSites: Array<any>;
	public selectedSites: Array<any> = [];
	editManager: FormGroup;
	hitCount: number = 0;
	status: boolean;
	file: File;
	size: number;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private apiService: ApiService, 
		private _location: Location,
		private mapsAPILoader: MapsAPILoader, 
		private fb: FormBuilder, 
		private toastr: ToastrService,
		public dialog: MatDialog,
		private ngZone: NgZone,
		) {
		this.editManager = this.fb.group({
			userName: new FormControl({value: '', enable: true }, Validators.required),
			firstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
			lastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
			email: new FormControl('', Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)),
			// email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
			phoneNo: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(6), Validators.maxLength(25)]),
			countryCode: new FormControl('', [Validators.required, Validators.pattern(/^\+[1-9][0-9]*$/)]),
			gender: new FormControl('', Validators.required),
			address: new FormControl(''),
			status: new FormControl(''),
			role: new FormControl(''),
			language: new FormControl(''),
		});
	}

	ngOnInit() {
		// this.mapsAPILoader.load().then(() => {
		// 	let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
		// 	// console.log(this.searchElementRef.nativeElement)
		// 	autocomplete.addListener("place_changed", () => {
		// 		this.ngZone.run(() => {
		// 			let place: google.maps.places.PlaceResult = autocomplete.getPlace();
		// 			if (place.geometry === undefined || place.geometry === null) {
		// 				return;
		// 			}
		// 		});
		// 	});
		// });

		this.selected = this.route.snapshot.paramMap.get('id');

		if (this.selected) {
			this.getManager();
		}

		this.getAllSites();
	}

	back() {
		this.router.navigate(['/managers']);
	}

	getManager() {
		this.apiService.getManager(this.selected).subscribe(data => {
				data.data.phoneNo = data.data.phoneNo.replace(/[^\d]+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3');
				this.manager = data.data;
				this.filteredAssignedSites = this.manager.scheduleSites;
				this.filteredUnassignedSites = this.manager.unassignedSites;
				this.editManager.setValue({
					userName: data.data.userName,
					firstName: data.data.firstName,
					lastName: data.data.lastName,
					email: data.data.email,
					phoneNo: data.data.phoneNo,
					countryCode: data.data.countryCode,
					gender: data.data.gender,
					address: data.data.address,
					status: data.data.isBlocked,
					role: data.data.role,
					language: data.data.language 
				});
				if( !data.data.address || data.data.address == 'null' ) this.editManager.patchValue({address: ''});
			},
			error => {
				if(error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");				
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
	}

	getAllSites() {
		this.apiService.getAllSites().subscribe(data => {
			this.sites = data.data.siteDetails;
		},
		error => {
			if(error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");				
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});
	}

	fileSelect(imageData) {		
		this.size = imageData.size;
		if (imageData.size > 3072000) {
			this.toastr.error('Payload content size should be less than 3MB', 'Error');
		} else {
			this.file = imageData;
		}
	}

	updateManager() {
		if (this.editManager.valid && this.selected) {
			this.submitted = true;

			let formData: FormData = new FormData();
			this.editManager.value.countryCode = this.editManager.value.countryCode.replace(/[^\d]+/g, '').replace(/(\d{1})/, '+$1');
			this.editManager.value.phoneNo = this.editManager.value.phoneNo.replace(/[^\d]+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');

			formData.append('managerId', this.selected);
			formData.append('email', this.editManager.value.email.trim());
			formData.append('firstName', this.editManager.value.firstName.trim());
			formData.append('lastName', this.editManager.value.lastName.trim());
			formData.append('phoneNo', this.editManager.value.phoneNo.trim());
			formData.append('countryCode', this.editManager.value.countryCode.trim());
			formData.append('gender', this.editManager.value.gender.trim());
			formData.append('isBlocked', this.editManager.value.status);
			formData.append('address', this.editManager.value.address.trim());
			formData.append('role', this.editManager.value.role == 'Manager' ? 'manager' : 'super_manager');
			formData.append('language', this.editManager.value.language);
			if (this.file) {
				formData.append('profilePic', this.file);
			}

			this.apiService.updateManager(formData).subscribe(data => {
				this.submitted = false;
				this.toastr.success('', data.message);
				this.back();
			},
			error => {
				this.submitted = false;
				if(error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");				
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}
	}

	assignSiteUpdate() {
		if (this.selectedSites.length > 0) {
			// if (this.scheduleValue) {
				this.assignSite('schedule');
			// }

			// if (this.watchValue) {
				// this.assignSite('watch');
			// }
		}
	}

	assignSite(_type) {
		let formData: FormData = new FormData();

		let arr: Array<any> = [this.selected];

		formData.append('managerId', JSON.stringify(arr));
		formData.append('siteId', JSON.stringify(this.selectedSites));
		formData.append('type', _type);

		this.apiService.assignManager(formData).subscribe(data => {
			this.selectedSites = [];
			this.scheduleValue = null;
			this.watchValue = null;
			this.getManager();			
			this.hitCount++;
			if (this.hitCount > 1) {
				this.hitCount = 0;
				this.toastr.success('', data.message);
			}
		},
		error => {
			if(error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");				
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});
	}

	unassignSiteUpdate(_id) {
				this.unassignSite(_id, 'schedule');
				// this.unassignSite(_id, 'watch');
	}

	unassignSite(_id, _type) {
		let formData: FormData = new FormData();

		let arr1: Array<any> = [this.selected];
		let arr2: Array<any> = [_id];
		let arr3: Array<any> = ['schedule', 'watch'];

		formData.append('managerId', JSON.stringify(arr1));
		formData.append('siteId', JSON.stringify(arr2));
		formData.append('type', JSON.stringify(arr3));	

		this.apiService.unAssignManager(formData).subscribe(data => {
			this.getManager();				
			this.hitCount++;
			if (this.hitCount > 1) {
				this.hitCount = 0;
				this.toastr.success('', data.message);
			}
			// this.toastr.success('', data.message);
		},
		error => {
			if(error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");				
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});
	}

	resetPassword() {
		this.apiService.resendOTPForManager(this.editManager.value.userName).subscribe(data => {			
			this.toastr.success('', data.message);
		},
		error => {
			if(error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");				
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});
	}

	newAssignSiteUpdate (_id) {
				this.newAssignSite(_id, 'schedule');
				// this.newAssignSite(_id, 'watch');
	}

	newAssignSite (_id, _type) {
		let formData: FormData = new FormData();

		let arr1: Array<any> = [this.selected];
		let arr2: Array<any> = [_id];		
		let arr3: Array<any> = ['schedule', 'watch'];

		formData.append('managerId', JSON.stringify(arr1));
		formData.append('siteId', JSON.stringify(arr2));
		formData.append('type', JSON.stringify(arr3));

		this.apiService.assignManager(formData).subscribe(data => {
			this.selectedSites = [];
			this.scheduleValue = null;
			this.watchValue = null;
			this.getManager();			
			this.hitCount++;
			if (this.hitCount > 1) {
				this.hitCount = 0;
				this.toastr.success('', data.message);
			}
		},
		error => {
			if(error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");				
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});
	}

	statusChange(status) {
		console.log(status,'status');		
	}

	assignCopy() {
		this.filteredAssignedSites = Object.assign([], this.manager.scheduleSites);
	}

	filterAssignedSite(value) {
		if (!value) 
			this.assignCopy();

		this.filteredAssignedSites = Object.assign([], this.manager.scheduleSites).filter(
			item => item.siteName && item.siteName.toLowerCase().indexOf(value.toLowerCase()) > -1
		)
	}

	unassignCopy() {
		this.filteredUnassignedSites = Object.assign([], this.manager.unassignedSites);
	}

	filterUnassignedSite(value) {
		if (!value) 
			this.unassignCopy();

		this.filteredUnassignedSites = Object.assign([], this.manager.unassignedSites).filter(
			item => item.siteName && item.siteName.toLowerCase().indexOf(value.toLowerCase()) > -1
		)
	}
}
