import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from "../../services/auth/auth.service";

@Injectable()
export class  AuthGuard implements CanActivate {

	constructor(private router: Router, private authService: AuthService) { }

	// canActivate(
	// 	next: ActivatedRouteSnapshot,
	// 	state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
	// 	return true;
	// }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.authService.hasLoggedIn()) {
			return true;
		}

		this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
		return false;

		// let url: string = state.url;
		// return this.checkLogin(url);
	}

	// canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
	// 	return this.canActivate(route, state);
	// }

	checkLogin(url: string): boolean {
		console.log(url, this.authService.isLoggedIn)
		if (this.authService.isLoggedIn) { 
			return true; 
		}

		this.authService.redirectUrl = url;

		this.router.navigate(['/login']);
		return false;
	}
}
