import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth/auth.guard';

import { LoginComponent } from './components/user/login/login.component';
import { LogoutComponent } from './components/user/logout/logout.component';
import { ChangePasswordComponent } from './components/user/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';

import { ProfileUpdateComponent } from './components/user/profile-update/profile-update.component';
import { CpasswordComponent } from './components/user/cpassword/cpassword.component';
import { BillingComponent } from './components/billing/billing.component';

export const ROUTES: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: 'forgotpassword', component : ForgotPasswordComponent },
	{ path: 'changepassword', component : ChangePasswordComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'user', canActivate: [AuthGuard], children: [
	        { path: '\profile-update', component: ProfileUpdateComponent, data: { title: 'Update Profile' }},
	        { path: 'cpassword', component: CpasswordComponent, data: { title: 'Change Password' }}
        ]
    },
	{ path: 'sites', redirectTo: '/sites', pathMatch: 'full', data: { title: 'Sites' }},
	{ path: 'managers', redirectTo: '/managers', pathMatch: 'full', data: { title: 'Managers' }},
	{ path: 'billing', component : BillingComponent, data: { title: 'Billing' }},
	{ path: '**', redirectTo: '/sites', pathMatch: 'full' },
	// { path: 'dashboard', redirectTo: '/sites', pathMatch: 'full', data: { title: 'Dashboard' } }
];

@NgModule({
	imports: [
	RouterModule.forRoot(
		ROUTES,
		{ enableTracing: false }
		)
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {}