import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ManagerAllComponent } from '../components/managers/manager-all/manager-all.component';
import { ManagerAddComponent } from '../components/managers/manager-add/manager-add.component';
import { ManagerProfileUpdateComponent } from '../components/managers/manager-profile-update/manager-profile-update.component';

import { ApiService } from '../services/api.service';
import { ManagerRoutingModule } from './managers-routing.module';

import { UiSwitchModule } from 'ng2-ui-switch';
import {MatDialogModule} from '@angular/material/dialog';

import { AgmCoreModule } from '@agm/core';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ManagerRoutingModule,
		MatProgressSpinnerModule,
		UiSwitchModule,
		MatDialogModule,
		AgmCoreModule.forRoot({
            apiKey: "AIzaSyCnO4puy0PYb5fZJD5g_S6fLtmY7BmHGa8",
            libraries: ["places"]
        })
	],
	declarations: [
		ManagerAllComponent,
		ManagerAddComponent,
		ManagerProfileUpdateComponent
	],
	providers: [ ApiService ]
})
export class ManagersModule {}