import { Component, OnInit } from '@angular/core';
import { Router, Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { Observable, Scheduler } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import * as _swal from 'sweetalert';
import * as _ from 'underscore';
import { SweetAlert } from 'sweetalert/typings/core';
const swal: SweetAlert = _swal as any;

@Component({
	selector: 'app-site-details',
	templateUrl: './site-details.component.html',
	styleUrls: ['./site-details.component.css']
})

export class SiteDetailsComponent implements OnInit {

	selected: string;
	scheduleValue: boolean;
	watchValue: boolean;
	hitCount: number = 0;
	public site: any;
	public managers: any = [];
	public managerss: Array<any>;

	public selectedManagers: Array<any> = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private apiService: ApiService,
		private _location: Location,
		private toastr: ToastrService,
		) { }

	ngOnInit() {
		this.selected = this.route.snapshot.paramMap.get('id');

		if (this.selected) {
			this.getSite();
		}
	}

	back() {
		// this._location.back();
		this.router.navigate(['/sites']);
	}

	getSite() {
		this.apiService.getSite(this.selected).subscribe(data => {
			this.site = data.data;
			this.getAllManagers();
		},
		error => {
			if(error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");				
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});
	}

	getAllManagers() {
		this.apiService.getAllManagers().subscribe(data => {
			// Method 1
			// let allManagerIds = data.data.ManagersArray.map(x => x._id.toString());
			// // console.log('allManagerIds--', allManagerIds);
			// let assignedManagerIds = this.site.scheduleManager.map(x => x._id.toString());
			// // console.log('assignedManagerIds--', assignedManagerIds)
			// let unassignedManagerIds = _.difference(allManagerIds, assignedManagerIds);
			// // console.log('unassignedManagerIds--', unassignedManagerIds);
			// for (let i = 0; i < data.data.ManagersArray.length; i++) {
			// 	let index = _.findIndex(unassignedManagerIds, (y) => y == data.data.ManagersArray[i]._id.toString());
			// 	console.log(index);
			// 	if (index != -1) this.managers.push(data.data.ManagersArray[i]);
			// }

			// Method 2
			this.managers = _.filter(data.data.ManagersArray, (x) => _.findIndex(this.site.scheduleManager, (y) => y._id.toString() == x._id.toString()) != -1 ? false : true)

			// console.log('this.managers===>', (this.managers), '--this.managers');
			// console.log('this.site.scheduleManager===>', (this.site.scheduleManager), '--this.site.scheduleManager');
		},
			error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
	}

	// assignManagerUpdate() {
	// 	console.log('this.selectedManagers===>', JSON.stringify(this.selectedManagers), '--this.selectedManagers');
	// 	if (this.selectedManagers.length > 0) {
	// 		// if (this.scheduleValue) {
	// 			this.assignManager('schedule');
	// 		// }

	// 		// if (this.watchValue) {
	// 			this.assignManager('watch');
	// 		// }
	// 	}
	// }

	// assignManager(_type) {
	// 	let formData: FormData = new FormData();

	// 	let arr: Array<any> = [this.selected];

	// 	formData.append('managerId', JSON.stringify(this.selectedManagers));
	// 	formData.append('siteId', JSON.stringify(arr));
	// 	formData.append('type', _type);

	// 	this.apiService.assignManager(formData).subscribe(data => {
	// 		this.selectedManagers = [];
	// 		this.scheduleValue = null;
	// 		this.watchValue = null;
	// 		this.getSite();
	// 		this.hitCount++;
	// 		if (this.hitCount > 1) {
	// 			this.hitCount = 0;
	// 			this.toastr.success('', data.message);
	// 		}
	// 	},
	// 	error => {
	// 		if(error.statusText == 'Unauthorized') {
	// 			window.open("/logout", "_self");
	// 		} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
	// 	});
	// }

	unassignManagerUpdate(_id) {
		this.unassignManager(_id);
		// this.unassignManager(_id, 'watch');
	}

	unassignManager(_id) {
		let formData: FormData = new FormData();

		let arr1: Array<any> = [_id];
		let arr2: Array<any> = [this.selected];
		// let arr3: Array<any> = [_type];

		formData.append('managerId', JSON.stringify(arr1));
		formData.append('siteId', JSON.stringify(arr2));
		formData.append('type', JSON.stringify(['watch', 'schedule']));
		// formData.append('type', _type);

		this.apiService.unAssignManager(formData).subscribe(data => {
			for (let index = 0; index < this.site.scheduleManager.length; index++) {
				if (this.site.scheduleManager[index]._id == _id) {
					this.managers.push(this.site.scheduleManager[index]);
					this.site.scheduleManager.splice(index, 1);
				}
			}
			// this.getSite();
			// this.getAllManagers();
			this.hitCount++;
			if (this.hitCount > 1) {
				this.hitCount = 0;
				this.toastr.success('', data.message);
			}
			// this.toastr.success('', data.message);
		},
			error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
	}

	singleAssignManagerUpdate(_id) {
		// this.singleAssignManager(_id, 'schedule');
		// this.singleAssignManager(_id, 'watch');
		this.singleAssignManager(_id);
	}

	// singleAssignManager(_id, _type) {
	singleAssignManager(_id) {
		let formData: FormData = new FormData();

		let arr1: Array<any> = [_id];
		let arr2: Array<any> = [this.selected];

		formData.append('managerId', JSON.stringify(arr1));
		formData.append('siteId', JSON.stringify(arr2));
		formData.append('type', JSON.stringify(['watch', 'schedule']));
		// formData.append('type', _type);

		this.apiService.assignManager(formData).subscribe(data => {
			// this.getSite();
			// this.getAllManagers();
			for (let index = 0; index < this.managers.length; index++) {
				if (this.managers[index]._id == _id) {
					this.site.scheduleManager.push(this.managers[index]);
					this.managers.splice(index, 1);
				}
			}
			this.hitCount++;
			this.selectedManagers = [];
			this.scheduleValue = null;
			this.watchValue = null;
			if (this.hitCount > 1) {
				this.hitCount = 0;
				this.toastr.success('', data.message);
			}
		},
			error => {
				if (error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
	}
}