import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.css']
})

export class LogoutComponent implements OnInit {

	constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService
		) { }

	ngOnInit() {
		localStorage.clear();
		if (localStorage.getItem('access_token')) {
			this.apiService.logout(localStorage.getItem('access_token')).subscribe(data => {
				this.toastr.success('', data.message);;
			},
			error => {
				this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}

		window.open("/login", "_self");
	}

}
