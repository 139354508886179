import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, Routes, RouterModule } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import { Lightbox } from 'angular2-lightbox';

@Component({
	selector: 'app-profile-update',
	templateUrl: './profile-update.component.html',
	styleUrls: ['./profile-update.component.css']
})

export class ProfileUpdateComponent implements OnInit {

	profileUpdate: FormGroup;
	public profile: any;
	private albums: Array<any>;
	submitted: boolean = false;
	file: File;

	constructor(
		private apiService: ApiService, 
		private fb: FormBuilder,
		private toastr: ToastrService,
		// private _lightbox: Lightbox
		) { this.createForm(); }

	ngOnInit() {
		this.getCompanyProfile();
	}

	getCompanyProfile() {
		this.apiService.getCompanyProfile().subscribe(data => {
			this.profile = data.data.CompanyArray[0];
			this.profileUpdate.setValue({
				name:    this.profile.name,
				email: this.profile.email
			});
		},
		error => {
			if(error.statusText == 'Unauthorized') {
				window.open("/logout", "_self");				
			} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
		});
	}

	createForm() {
		this.profileUpdate = this.fb.group({
			name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]+')]),
			email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')])
		});
	}

	fileSelected(imageData) {
		this.file = imageData;
	}

	open(thumbnail, original): void {
		this.albums = [{
			src: original,
			thumb: thumbnail
		}];

		// this._lightbox.open(this.albums, 0);
	}
	
	updateCompany(){
		if (this.profileUpdate.valid) {
			this.submitted = true;

			let formData: FormData = new FormData();
			
			formData.append('name', this.profileUpdate.value.name);
			formData.append('email', this.profileUpdate.value.email);
			
			if(this.file) {
				formData.append('companyLogo', this.file);
			}
			
			this.apiService.updateCompany(formData).subscribe(data => {
				this.toastr.success('', data.message);
				this.getCompanyProfile();
				this.submitted = false;
			},
			error => {
				this.submitted = false;
				if(error.statusText == 'Unauthorized') {
					window.open("/logout", "_self");				
				} else this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
			});
		}
	}

}
