import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { tap, delay } from 'rxjs/operators';

@Injectable()
export class AuthService {

	isLoggedIn: boolean = false;
	redirectUrl: string;
	authState = new BehaviorSubject<boolean>(false);

	constructor(public http: Http) {
		const localToken = localStorage.getItem('access_token');
		if (localToken) {
			this.isLoggedIn = true;
		}
		this.authState.next(!!localToken);
	}

	// login(): Observable<boolean> {
	// 	return of(true).pipe(
	// 		delay(1),
	// 		tap(val => this.isLoggedIn = true)
	// 		);
	// }

	logout(): void {
		this.isLoggedIn = false;
	}

	setup(token: any) {
		localStorage.setItem('access_token', token);
		this.authState.next(true);
	}

	signOut(): void {
		localStorage.clear();
		this.authState.next(false);
	}

	hasLoggedIn(): any {
		return !!(localStorage.getItem("access_token"));
	}

}
