import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "../.././../services/auth/auth.service";

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	
	loginForm: FormGroup;
	eye = 'show';
	eyeSlash = 'hide';
	submitted: boolean = false;

	constructor(private apiService: ApiService, private router: Router, private fb: FormBuilder, private toastr: ToastrService, private authService: AuthService) {
		this.loginForm = this.fb.group({
			userName: new FormControl('', Validators.required),
			password: new FormControl('', Validators.required)
		});
	}

	ngOnInit() {
		if (sessionStorage.getItem("companyId")) {			
			this.toastr.error('Session Expired', 'Please login again');
		}else if (localStorage.getItem("access_token")) {
			window.open("/sites", "_self");
		}
	}

	showPassword(pass: any): any {
  		pass.type = 'text';
  		this.eye = 'hide';
  		this.eyeSlash = 'show';
  	}

  	hidePassword(pass: any): any {
  		pass.type = 'password';
  		this.eye = 'show';
  		this.eyeSlash = 'hide';
  	}

	login() {
		if (this.loginForm.valid) {
			this.submitted = true;
			this.apiService.login(this.loginForm.value.userName, this.loginForm.value.password).subscribe(data => {
				this.authService.isLoggedIn = true;
				localStorage.setItem('companyId', data.data.companyId);
				sessionStorage.setItem('companyId', data.data.companyId);
				this.toastr.success('', data.message);
				this.submitted = false;
				if (data.data.firstTimeLogin) {
					localStorage.setItem('firstTimeLogin_token', data.data.access_token);
					this.router.navigate(['/changepassword']);
					// window.open("/changepassword", "_self");
				} else {
					localStorage.setItem('access_token', data.data.access_token);
					// this.router.navigate(['/sites']);
					window.open("/sites", "_self");
				}
			},
			error => {
				this.toastr.error(JSON.parse(error._body).message, JSON.parse(error._body).error);
				this.submitted = false;
			});
		}
	}
}
